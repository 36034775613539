const isBrowser = typeof window !== "undefined"

export const calculateDarkMode = () => {
  if ( isDarkModeEnabled() ) {
    enableDarkMode();
  }
}

export const isDarkModeEnabled = (): boolean => {
  const fromStorage = isDarkModeEnabledFromLocalStorage();
  const fromScheme = !hasDarkModeInLocalStorage() && isDarkModeEnabledFromColorScheme();

  return fromStorage || fromScheme;
}

export const hasDarkModeInLocalStorage = (): boolean => {
  if ( !isBrowser ) {
    return false;
  }
  return window?.localStorage?.getItem('dark-mode') != null;
}

export const isDarkModeEnabledFromLocalStorage = (): boolean => {
  if ( !isBrowser ) {
    return false;
  }
  return window?.localStorage?.getItem('dark-mode') === 'enabled';
}

export const isDarkModeEnabledFromColorScheme = (): boolean => {
  if ( !isBrowser ) {
    return false;
  }
  return window?.matchMedia('(prefers-color-scheme)').media !== 'not all';
}


export const enableDarkMode = () => {
  if ( !isBrowser ) {
    return;
  }
  const rootHtml = window?.document?.documentElement;
  rootHtml?.classList?.remove('theme-default');
  rootHtml?.classList?.add('theme-dark');

  window?.localStorage?.setItem('dark-mode', 'enabled');
}

export const disableDarkMode = () => {
  if ( !isBrowser ) {
    return;
  }
  const rootHtml = window?.document?.documentElement;
  rootHtml?.classList?.add('theme-default');
  rootHtml?.classList?.remove('theme-dark');
  window?.localStorage?.setItem('dark-mode', 'disabled');
}

export const toggleDarkMode = () => {
  if ( isDarkModeEnabled() ) {
    disableDarkMode();
  } else {
    enableDarkMode();
  }
}
