export const supportedLanguages = ['en', 'pt'];

export const getBrowserLanguage = (): string => {
  const browserLangs = navigator.languages
    .map(lang => lang.split('-')?.[0])
    .filter(lang => lang?.length);

  const supportedLang = Array.from( new Set(browserLangs) ).find(navLang => {
    const navLangCode = navLang.split('-')?.[0];
    return supportedLanguages.includes(navLangCode);
  });
  return supportedLang || supportedLanguages[0];
}

export const verifyLanguageRoute = (): void => {
  if ( !hasLanguageInRoute() ) {
    addLanguageToCurrentRoute();
  }
}

export const hasLanguageInRoute = (): boolean => {
  const splitLocation = location.pathname.split('/');
  return splitLocation?.length > 2 && supportedLanguages.includes( splitLocation[1] );
}

export const addLanguageToCurrentRoute = (): void => {
  let splitLocation = location.pathname.split('/');
  splitLocation.splice(1, 0, getBrowserLanguage());

  window.location.replace( window.location.origin + splitLocation.join('/') );
}
