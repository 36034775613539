import "./src/styles/normalize.scss";

import "./src/styles/theme.scss";
import "./src/styles/variables.scss";
import "./src/styles/style.scss";
import "./src/styles/post-code-fix.scss";

import { calculateDarkMode } from './src/utils/dark-mode';
import { verifyLanguageRoute } from "./src/utils/language";

calculateDarkMode();
verifyLanguageRoute();

